// import { Input } from "@/components/ui/input";
import useTheme from 'hooks/useTheme'
import Link from 'next/link'
import { useEffect, useState } from 'react'

function SearchToken() {
  const { theme } = useTheme()
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState<{ address: string }[]>([])

  const search = async (keyword: string) => {
    // refetch({ search: keyword });
    fetch(`https://data-api.poocoin.app/search-tokens-bsc?search=${keyword}`)
      .then((res) => res.json())
      .then((res) => {
        setSearchResults(res)
      })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (searchTerm && searchTerm.length > 2) {
      search(searchTerm)
    }
  }
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setSearchTerm('')
  }

  // Debounce search input by 500ms
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // if (searchTerm && searchTerm.length > 2) {
      search(searchTerm)
      // }
    }, 500)
    return () => clearTimeout(timeoutId) // Clear the timeout if the component is unmounted or the search term changes
  }, [searchTerm])

  return (
    <div className="relative flex justify-center" style={{
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
    }}>
      <input
        className="w-64"
        style={{
          borderRadius: 16,
          border: '1px solid rgb(215, 202, 236)',
          height: 48,
          minWidth: 320,
          padding: '0 16px',
          fontSize: 16,
          backgroundColor: theme.colors.input,
          color: theme.colors.text,
        }}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder="Search token name or address"
      />
      {searchResults.length > 0 && (
        <div
          className="absolute top-12 rounded-md border bg-white p-4 text-left"
          style={{
            position: 'absolute',
            top: 56,
            padding: 16,
            borderRadius: 8,
            border: '1px solid rgb(215, 202, 236)',
            backgroundColor: theme.modal.background,
          }}
        >
          {searchResults.map((i) => (
            <div key={i.address} className="border-b py-4"
            style={{
              borderBottom: '1px solid rgb(215, 202, 236)',
              paddingBottom: 16,
              paddingTop: 16,
            }}
            >
              <div
                className="flex items-center"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  src={`https://r.poocoin.app/smartchain/assets/${i.address}/logo.png`}
                  className="h-8 w-8 mr-2"
                  style={{
                    width: 32,
                    height: 32,
                    marginRight: 8,
                  }}
                  // @ts-ignore
                  onError={(e) => (e.target.style.opacity = '0')}
                />
                <Link
                  href={`/?address=${i.address}`}
                  // search={{
                  //   address: i.address,
                  // }}
                  style={{
                    color: theme.colors.text,
                  }}
                >
                  {i.address}
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default SearchToken
