import { BigNumber } from 'bignumber.js'
import { useERC20 } from 'hooks/useContract'
import useTheme from 'hooks/useTheme'
import * as React from 'react'

const TokenInfo: React.FC<{ info: any; address: string }> = ({ info: tokenInfo, address }) => {
  const { theme } = useTheme()
  const contract = useERC20(tokenInfo.id || address)

  const [totalSupply, setTotalSupply] = React.useState<string>()
  const fetData = async () => {
    const totalSupply = new BigNumber(Number(await contract?.read.totalSupply()))
    setTotalSupply(totalSupply.div(1e18).toFormat(6))
  }

  React.useEffect(() => {
    if (tokenInfo) {
      fetData()
    }
  }, [contract])

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img
          src={
            address === '0x0C8C133d6765218c7e88e0F9dD4DCEE05ee779D0'
              ? tokenInfo.icon
              : `https://r.poocoin.app/smartchain/assets/${tokenInfo?.id}/logo.png`
          }
          className="h-8 w-8"
          style={{
            width: 32,
            height: 32,
          }}
        />
        <div
          className="ml-2"
          style={{
            padding: '16px 0px',
            marginLeft: 8,
          }}
        >
          <p
            className="font-bold"
            style={{
              fontSize: '24px',
              lineHeight: '29px',
              color: theme.colors.text,
              fontWeight: 700,
            }}
          >
            {tokenInfo?.name} ({tokenInfo?.symbol})
          </p>
        </div>
      </div>
      <p style={{ color: theme.colors.text }}>Total Supply: {totalSupply}</p>
      {/* <a style={{color: theme.colors.primaryBright}} target="_blank" href={`https://bscscan.com/address/${tokenInfo.id}#code`}>
        Contract
      </a>
      <a style={{color: theme.colors.primaryBright}} target="_blank" href={`https://bscscan.com/token/${tokenInfo.id}`}>
        Transaction
      </a>
      <a style={{color: theme.colors.primaryBright}} target="_blank" href={`https://bscscan.com/token/${tokenInfo.id}#balances`}>
        Holder
      </a> */}
    </div>
  )
}

export default TokenInfo
