import { Box, useMatchBreakpoints } from '@pancakeswap/uikit'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useTheme from 'hooks/useTheme'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { getPancakePairContract } from 'utils/contractHelpers'
import { usePublicClient, useWalletClient } from 'wagmi'
// import { TVChartContainer } from './TvChartContainer'
import dynamic from 'next/dynamic'
import Script from 'next/script'
import SearchToken from './SearchToken'
import TokenInfo from './TokenInfo'
import TransactionLog from './TransactionLog'
import PubSub from './pubsub'

const TVChartContainer = dynamic(() => import('./TvChartContainer').then((mod) => mod.TVChartContainer), { ssr: false })

export const pubSubInstance = new PubSub()

const BNB_ADDRESS = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'

const Home: React.FC<React.PropsWithChildren> = () => {
  const { theme } = useTheme()
  const { isMobile } = useMatchBreakpoints()
  const { chainId: activeChainId } = useActiveChainId()
  const provider = usePublicClient({ chainId: 56 })
  const { data: signer } = useWalletClient()

  const router = useRouter()

  const [tokenInfo, setTokenInfo] = useState<any>()
  const [tokenDataTrustWallet, setTokenDataTrustWallet] = useState<any>()
  const [bnbPosition, setBnbPosition] = useState<number>(0)
  const [latestBlock, setLatestBlock] = useState<number>(0)
  const [transactionLog, setTransactionLog] = useState<any>()

  const fetchData = async (tokenAddress: string) => {
    const tokenInfo = fetch(`https://data-api.poocoin.app/token-info-bsc`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([tokenAddress]),
    }).then((res) => res.json())
    const trustWalletTokenData = fetch(`https://r.poocoin.app/smartchain/assets/${tokenAddress}/info.json`).then(
      (res) => res.json(),
    )

    const [tokenInfoResult, trustWalletTokenDataResult] = await Promise.all([tokenInfo, trustWalletTokenData])

    const pancakePairContract = getPancakePairContract(tokenInfoResult[0].lpAddress, signer)
    const token0 = await pancakePairContract.read.token0()
    const bnbPosition = token0 === BNB_ADDRESS ? 0 : 1

    setTokenInfo(tokenInfoResult[0])
    if (tokenAddress === "0x0C8C133d6765218c7e88e0F9dD4DCEE05ee779D0") {
      setTokenDataTrustWallet({
        id: "0x0C8C133d6765218c7e88e0F9dD4DCEE05ee779D0",
        name: "X Token",
        symbol: "X",
        decimals: 18,
        icon: "https://minex.network/XPLUS.png",
      })
    } else {
      setTokenDataTrustWallet(trustWalletTokenDataResult)
    }
    setBnbPosition(bnbPosition)

    //   const chainLinkBnbUsdContract = getChainLinkContract(
    //     CHAINLINK_BNB_USD_ADDRESS,
    //     ETHER_PROVIDER,
    //   );
    //   const bnbPriceUSD = await chainLinkBnbUsdContract.latestAnswer();

    const latestBlock = await provider.getBlockNumber()
    setLatestBlock(Number(latestBlock))

    const transactionLog = await fetch(`https://data-api.poocoin.app/swap-logs-bsc?`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jsonrpc: '2.0',
        id: Number(`${Math.floor(Math.random() * 100000)}` + `${new Date().getTime()}`),
        // id: 29159534455518112,
        method: 'eth_getLogs',
        params: [
          {
            fromBlock: '0x' + (Number(latestBlock) - 1000).toString(16),
            toBlock: 'latest',
            topics: [
              '0xd78ad95fa46c994b6551d0da85fc275fe613ce37657fb8d5e3d130840159d822',
              '0x19b47279256b2a23a1665c810c8d55a1758940ee09377d4f8d26497a3577dc83',
            ],
            address: tokenInfoResult[0].lpAddress,
          },
        ],
      }),
    }).then((res) => res.json())

    setTransactionLog(transactionLog)
  }

  useEffect(() => {
    const tokenAddress = window.location.search.split('=')[1]
    if (tokenAddress) {
      fetchData(tokenAddress)
    } else {
      // fetchData("0x55d398326f99059fF775485246999027B3197955")
      router.replace('/?address=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82')
    }
  }, [router.query.address])

  const [isScriptReady, setIsScriptReady] = useState(false)

  return (
    <>
      <Script
        src="/static/datafeeds/udf/dist/bundle.js"
        strategy="lazyOnload"
        onReady={() => {
          setIsScriptReady(true)
        }}
      />
      <Box style={{ width: '100%', maxWidth: 1024, margin: '0 auto', paddingBottom: 60, paddingLeft: 8, paddingRight: 8 }}>
        {tokenDataTrustWallet && (
          <TokenInfo info={tokenDataTrustWallet} address={tokenInfo.address} />
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '16px 0px',
          }}
        >
          <SearchToken />
        </div>

        <div className="h-[500px]">
          {isScriptReady && tokenInfo && (
            <TVChartContainer
              lpAddress={tokenInfo?.lpAddress as string}
              bnbPosition={bnbPosition}
              name={tokenDataTrustWallet?.name}
              symbol={tokenDataTrustWallet?.symbol}
            />
          )}
        </div>

        <div className="mt-10 relative z-0">
          {transactionLog?.result && tokenInfo && bnbPosition != null && (
            <TransactionLog
              data={transactionLog?.result}
              bnbPosition={bnbPosition}
              // bnbPrice={bnbPrice}
              decimals={tokenDataTrustWallet?.decimals}
              lpAddress={tokenInfo?.lpAddress}
              latestBlock={latestBlock}
              name={tokenDataTrustWallet?.symbol}
            />
          )}
        </div>
      </Box>
    </>
  )
}

export default Home
